import React from 'react'

import SEO from 'components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from 'components/layout'
import { Nav } from 'components/nav'
import { BackgroundWithGrid } from '../../components/layout'
import { LdJsonLogo, HeaderLink } from '../../components/semanticdata'
import Analytics from '../../components/analytics'

const ShowcaseGreenBalkansPage = () => (
  <Layout className="bg-primary-2 text-primary-3">
    <Analytics />
    <SEO
      title="Green Balkans"
      pageUrl="/showcase/greenbalkans/"
      description="The Green Balkans app showcases not only Tumba Solutions technical skills but also our dedication to social responsibility. By leveraging cutting-edge technology to support crucial conservation efforts, we demonstrate how software development can be a powerful force for positive change in the world."
    />
    <LdJsonLogo />
    <MainWithMenu className="grid">
      <Header className="z-20 pt-6 pb-24">
        <HeaderLink className="text-primary-1" />
        <div className="py-12 text-left">
          <h1 className="py-4 text-4xl lg:text-5xl text-primary-3 text-shadow">Green Balkans</h1>
          <p className="text-lg lg:text-xl text-shadow">A Flutter-Powered Solution Aligned with Tumba's Social Responsibility</p>
        </div>
      </Header>
      <BackgroundWithGrid image="greenbalkans-3-hero" gridRow={1} />
      <Deco className="z-10 bg-primary-2" decoType={2} before after gridRow={2} />
      <Section className="z-20" gridRow={2}>
        <h2 className="text-2xl lg:text-3xl text-primary-1">Preserving Nature through Digital Innovation</h2>
        <div className="lg:mr-48">
          {/*<h3 className='mt-4 text-lg text-primary-1'>Empowering Conservation Efforts with Technology</h3>*/}
          <p className="mt-2 font-normal">
            At Tumba Solutions, we believe in leveraging our technological expertise to make a positive impact on the world. Our collaboration with{' '}
            <a href="https://greenbalkans.org/en/" className="text-primary-1">
              Green Balkans
            </a>
            , Bulgaria's oldest wildlife conservation NGO, exemplifies our commitment to social responsibility—a core pillar of our company.
          </p>
          <h3 className="mt-4 text-lg text-primary-1">Green Balkans</h3>
          <p className="mt-2 font-normal">
            <a href="https://greenbalkans.org/en/" className="text-primary-1">
              Green Balkans
            </a>{' '}
            has been at the forefront of biodiversity protection and sustainable environmental practices in Bulgaria for decades. As the leading force in the{' '}
            <a href="https://en.wikipedia.org/wiki/Natura_2000" className="text-primary-1">
              Natura 2000
            </a>{' '}
            program for Bulgaria — part of the world's largest network of protected areas — Green Balkans plays a crucial role in safeguarding endangered species and ecosystems.
          </p>
          <h3 className="mt-4 text-lg text-primary-1">The Challenge</h3>
          <p className="mt-2 font-normal">
            Green Balkans' vital fieldwork, which includes tracking rare species, relied heavily on manual, paper-based data collection. This process was prone to errors, information loss, and inefficiencies, hindering their conservation efforts.
          </p>
        </div>
      </Section>

      <Deco before after className="bg-primary-1" gridRow={3} />
      <Section className="z-20 mt-12 pt-12 pb-12 text-primary-2" gridRow={3}>
        <h2 className="mt-6 text-2xl lg:text-3xl">A Digital Solution</h2>
        <div className="lg:mr-48">
          <p className="mt-2 font-normal">
            Recognizing the need for a digital transformation, Tumba Solutions volunteered to create a mobile application that would revolutionize Green Balkans' data collection process. We chose Flutter as our development framework for its ability to:
          </p>
          <ul className="pl-10 mt-2 list-disc font-normal">
            <li>Deliver a cross-platform solution for both Android and iOS devices.</li>
            <li>Ensure functionality in remote areas with limited or missing connectivity.</li>
            <li>Provide a user-friendly interface for efficient data entry and mapping.</li>
            <li>Enable secure synchronization with Green Balkans' main database.</li>
          </ul>
          <h3 className="mt-4 text-lg">Why We Chose Flutter</h3>
          <p className="mt-2 font-normal">
            After an extensive evaluation process, Flutter emerged as the clear winner for this project. Its modern framework allowed for rapid prototyping and development, perfectly aligning with our need for an efficient, cross-platform solution.
          </p>
          <h3 className="mt-4 text-lg">The Development Process</h3>
          <p className="mt-2 font-normal">
            This project was a true collaborative effort, engaging almost every member of the Tumba Solutions team. It served as an excellent opportunity for our entire company to contribute to a meaningful cause while expanding our technical expertise.
          </p>
        </div>
        <h2 className="mt-6 text-2xl lg:text-3xl">Future Impact: Citizen Science</h2>
        <div className="lg:mr-48">
          <p className="mt-2 font-normal">
            Looking ahead, Green Balkans plans to release this app to the public, enabling <a href="https://en.wikipedia.org/wiki/Citizen_science">Citizen Science</a> initiatives. This will enable volunteers and citizens to collaborate with experts, raising
            awareness about biodiversity loss and expanding the reach of conservation efforts.
          </p>
        </div>
      </Section>

      <BackgroundWithGrid image='greenbalkans-3-hero' bottom={true} gridRow={4} />
      <Deco style={{ background: 'rgba(0, 0, 0, 0.4)' }} gridRow={4} className="z-0" />
      {/*<Deco className="bg-primary-2" decoType={2} gridRow={4} />*/}
      <Deco decoType={1} before inner after gridRow={4} className="z-20" />
      <Section className="z-20 mt-16 mb-20 pt-16 pb-20" gridRow={4}>
        <h2 className="col-span-3 text-2xl lg:text-3xl text-shadow">
          A Testament to Our Commitment
        </h2>
        <div className="col-span-3 sm:col-span-1">
          <p className="mt-5 text-base font-normal tracking-normal">
            "We are grateful that companies like Tumba Solutions share our commitment to nature conservation. I am personally impressed by their dedication and willingness to support and facilitate our work. I am thankful to see young people in Bulgaria who
            are socially engaged, motivated, and eager to contribute to meaningful causes. These individuals radiate a positive and constructive energy that naturally inspires others and inevitably leads to something beneficial for society as a whole. It may
            sound a bit idealistic, but it comes sincerely from the heart."
          </p>
          <p className="mt-8">Elena Stoeva, chairperson of Green Balkans</p>
        </div>
      </Section>

      <Deco className="z-10 bg-primary-2" decoType={1} before gridRow={7} />
      <Nav className="z-20 text-primary-2" gridRow={7} />
    </MainWithMenu>
  </Layout>
)

export default ShowcaseGreenBalkansPage
